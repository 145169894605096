import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation, useSearchParams } from 'react-router-dom';
import ToastCustomized from '../../components/common/ToastCustomized';
import Header from '../../components/giftSelection/Header';
import ThankyouNotesBottom from '../../components/giftSelection/ThankyouNotesBottom';
import Api from '../../constants/api';
import { setIsLoading } from '../../redux/globalSlice';
import ThankyouNotesHamper from './ThankyouNotesHamper';
import ThankyouNotesProduct from './ThankyouNotesProduct';
import ThankyouNotesVoucher from './ThankyouNotesVoucher';

const ThankyouNotes = () => {
  const dispatch = useDispatch();
  const { state } = useLocation();
  const recipientId = useSearchParams()[0].get('recid');
  const [orderDetails, setOrderDetails] = useState<GetOrderResponseData>();
  const [data, setData] = useState<
    {
      title: string;
      value: string;
    }[]
  >([]);

  useEffect(() => {
    if (state?.id) {
      setOrderDetails(state);
    } else if (recipientId) {
      dispatch(setIsLoading(true));
      Api.getOrderFromUrlId(recipientId)
        .then((res) => {
          setOrderDetails(res.data);
          console.log(res.data);
        })
        .finally(() => dispatch(setIsLoading(false)));
    }
  }, [dispatch, recipientId, state]);

  useEffect(() => {
    if (!orderDetails) return;
    // TODO: Craete a proper state for fetching orders
    const {
      id,
      firstName,
      lastName,
      address,
      apartment,
      postalCode,
      phoneNumber,
      email,
      shippingMethod,
      voucherOrders,
      deliveryDate,
    } = orderDetails;
    const { isSelfPickupOnly, pickupAddress } = orderDetails.campaign;

    const deliDateTime = deliveryDate
      ? new Date(deliveryDate).toLocaleString('en-SG', {
          timeZone: 'Asia/Singapore',
        })
      : '-';

    const name = `${firstName} ${lastName}`.trim();

    let _data = [];
    if (voucherOrders.length === 0) {
      _data.push({ title: 'Order Number', value: id.toString() ?? '' });
    }
    if (isSelfPickupOnly) {
      _data.push(
        ...[
          { title: 'Recipient Name', value: name || '' },
          { title: 'Phone number', value: phoneNumber },
          { title: 'Email', value: email },
          { title: 'Pick-up Instructions', value: pickupAddress },
        ]
      );
    } else if (shippingMethod === 'Delivery') {
      _data.push(
        ...[
          { title: 'Delivery Type', value: 'Standard' },
          { title: 'Recipient Name', value: name || '' },
          {
            title: 'Delivery Address',
            value: address,
          },
          {
            title: 'Preferred Delivery Date and Time',
            value: deliDateTime,
          },
          {
            title: 'Apartment ',
            value: apartment,
          },
          {
            title: 'Postal Code ',
            value: postalCode,
          },

          { title: 'Phone number', value: phoneNumber },
          { title: 'Email', value: email },
        ]
      );
    } else if (shippingMethod === 'Self Pick up') {
      _data.push(
        ...[
          { title: 'Delivery Type', value: 'Pick-up' },
          { title: 'Pick-up Instructions', value: 'To be provided' },
        ]
      );
    } else if (voucherOrders.length > 0) {
      const { description, termsContent, expiryDays } =
        voucherOrders[0].voucher;

      _data.push(
        { title: 'Description', value: description },
        { title: 'Terms and Conditions', value: termsContent }
      );

      if (expiryDays !== null) {
        _data.push({
          title: 'Validity',
          value: `${expiryDays} days from activation.`,
        });
      }
    } else {
      _data.push(
        ...[
          { title: 'Recipient Name', value: name || '' },
          {
            title: 'Delivery Address',
            value: address,
          },
          {
            title: 'Preferred Delivery Date and Time',
            value: deliDateTime,
          },
          { title: 'Phone number', value: phoneNumber },
          { title: 'Email', value: email },
        ]
      );
    }

    setData(_data.filter(({ value }) => !!value));
  }, [orderDetails]);

  const productLength = orderDetails?.orders ? orderDetails?.orders.length : 0;
  const voucherLength = orderDetails?.voucherOrders
    ? orderDetails?.voucherOrders.length
    : 0;

  const isHamper = productLength + voucherLength > 1;
  const isVoucherOrder = voucherLength === 1;
  const isProductOrder = productLength === 1;

  return (
    <div className="min-h-screen bg-neutrals-200">
      <Header />
      {isHamper && orderDetails && (
        <ThankyouNotesHamper orderDetails={orderDetails} />
      )}
      {/* Single Gift component, TODO: make it into a single component*/}
      {!isHamper && isVoucherOrder && orderDetails && (
        <ThankyouNotesVoucher
          voucherOrder={orderDetails.voucherOrders[0]}
          dataForAccordin={data}
          email={orderDetails.email}
        />
      )}
      {isProductOrder && !isHamper && orderDetails && (
        <ThankyouNotesProduct orderDetails={orderDetails} data={data} />
      )}
      <ThankyouNotesBottom />
      <ToastCustomized />
    </div>
  );
};

export default ThankyouNotes;
