import { useLocation } from 'react-router-dom';
import Divider from '../../components/common/Divider';
import images from '../../constants/images';

type propsType = {
  orderDetails: GetOrderResponseData;
  data: {
    title: string;
    value: string;
  }[];
};

const ThankyouNotesProduct = (props: propsType) => {
  const state: { skipped: boolean } = useLocation().state;
  const skipped = state?.skipped || false;
  const { orderDetails, data } = props;
  const status = orderDetails.status.name;

  return (
    <>
      {orderDetails.shippingMethod === 'Delivery' ? (
        <div className="p-4 mt-[100px]">
          <div className="flex justify-center font-leagueSpartan-500 text-[32px]">
            My Gift Status
          </div>
          <div className="flex justify-center w-[40%] max-md:w-[90%] m-auto mt-6">
            <div className="relative">
              <img src={images.checkCircle} alt="" />
            </div>
            <div
              className={`border-2 ${
                ['ON_ROUTE', 'DELIVERED', 'COMPLETED'].includes(status)
                  ? 'border-green-500'
                  : 'border-neutrals-500'
              }  w-[40%] self-center`}
            ></div>
            <div className="relative mx-1">
              <img
                src={
                  ['ON_ROUTE', 'DELIVERED', 'COMPLETED'].includes(status)
                    ? images.truckGreen
                    : images.truckGrey
                }
                className="mt-1"
                alt=""
              />
            </div>
            <div
              className={`border-2 ${
                ['COMPLETED', 'DELIVERED'].includes(status)
                  ? 'border-green-500'
                  : 'border-neutrals-500'
              }  w-[40%] self-center`}
            ></div>
            <div className="relative translate-x-1">
              <img
                src={
                  ['COMPLETED', 'DELIVERED'].includes(status)
                    ? images.homeGreen
                    : images.homeGrey
                }
                className="mt-1"
                alt=""
              />
            </div>
          </div>
          <div className="grid grid-cols-3 w-[40%] max-md:w-[90%] m-auto">
            <div className="font-leagueSpartan-300 max-md:text-sm place-content-start flex top-4">
              Order Received
            </div>
            <div
              className={`font-leagueSpartan-300 max-md:text-sm place-content-center flex top-4 ${
                !['ON_ROUTE', 'DELIVERED', 'COMPLETED'].includes(status) &&
                'text-neutrals-600'
              }`}
            >
              On the way
            </div>
            <div
              className={`font-leagueSpartan-300 -mr-1 max-md:text-sm  flex place-content-end top-4 ${
                !['DELIVERED', 'COMPLETED'].includes(status) &&
                'text-neutrals-600'
              }`}
            >
              Delivered
            </div>
          </div>
        </div>
      ) : (
        <div className="p-4 mt-[100px]">
          <div className="flex justify-center font-leagueSpartan-500 text-[32px]">
            My Gift Status
          </div>
          <div className="flex justify-center w-[40%] max-md:w-[90%] m-auto mt-6">
            <div className="relative mx-1">
              <img src={images.checkCircle} alt="" />
            </div>
            <div
              className={`border-2 ${
                status === 'PROCURED' || status === 'COMPLETED'
                  ? 'border-green-500'
                  : 'border-neutrals-500'
              } w-[40%] self-center`}
            ></div>
            <div className="relative mx-1">
              <img
                src={
                  status === 'PROCURED' || status === 'COMPLETED'
                    ? images.giftGreen
                    : images.giftGrey
                }
                className="mt-1"
                alt=""
              />
            </div>
            <div
              className={`border-2 ${
                status === 'COMPLETED'
                  ? 'border-green-500'
                  : 'border-neutrals-500'
              } w-[40%] self-center`}
            ></div>
            <div className="relative mx-1">
              <img
                src={
                  status === 'COMPLETED'
                    ? images.collectedGreen
                    : images.collectedGrey
                }
                alt=""
              />
            </div>
          </div>
          <div className="grid grid-cols-3 w-[42%] max-md:w-[90%] m-auto mt-2">
            <div className="font-leagueSpartan-400 text-[16px] place-content-start flex top-4 text-neutrals-900">
              Order Received
            </div>
            <div
              className={`font-leagueSpartan-400 text-[16px] place-content-center flex top-4 ${
                !(status === 'PROCURED' || status === 'COLLECTED') &&
                'text-neutrals-600'
              } text-center`}
            >
              Gift Ready to Collect
            </div>
            <div
              className={`font-leagueSpartan-400 text-[16px] place-content-end flex top-4 ${
                !(status === 'COLLECTED') && 'text-neutrals-600'
              }`}
            >
              Collected
            </div>
          </div>
        </div>
      )}
      <div
        className={`mt-4 grid grid-cols-2 max-lg:grid-cols-1 gap-5 lg:mx-auto  ${
          skipped ? 'lg:w-[65%]' : 'lg:w-[40%]'
        }  max-lg:mx-6`}
      >
        <div
          className={`shadow rounded-lg ${
            !skipped && 'col-span-2'
          } bg-white p-6`}
        >
          <div className="font-leagueSpartan-500 text-[24px]">My Gift</div>
          {orderDetails.orders.map((order) => {
            const { image_1: image, name, id } = order.product || {};
            return (
              <div key={id} className="flex gap-4 my-2 py-2">
                <img
                  className="w-[80px] h-[80px] aspect-square rounded-xl object-contain"
                  src={image}
                  alt=""
                />
                <div>
                  <p className="font-leagueSpartan-400 text-[18px] text-neutrals-900">
                    {name}
                  </p>
                </div>
                <div className="grow text-right">
                  {!orderDetails.campaign.isSingle && (
                    <p className="font-leagueSpartan-400 text-[18px] text-neutrals-900">
                      x{order.quantity}
                    </p>
                  )}
                </div>
              </div>
            );
          })}
          <div className="my-6">
            <Divider />
          </div>
          {data.map((dataEntry) => (
            <div key={dataEntry.title} className="mt-4">
              <div className="font-leagueSpartan-400 text-[16px] text-neutrals-600">
                {dataEntry.title}
              </div>
              <div className="font-leagueSpartan-400 text-[16px] text-neutrals-900">
                {dataEntry.value + ' '}
                {dataEntry.title === 'Preferred Delivery Date and Time' && (
                  <span className="text-sm text-gray-400">
                    (**We will try our best to meet the time**)
                  </span>
                )}
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default ThankyouNotesProduct;
