import { fromZonedTime, toZonedTime } from 'date-fns-tz';
import en from 'date-fns/locale/en-US'; // Import Spanish locale as an example
import DatePicker, { registerLocale } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

// Register the Spanish locale
registerLocale('en-US', en);

// Define prop types
type propType = {
  title: string;
  date: string | null;
  onChange: (date: string) => void;
  error?: string;
  touched?: boolean;
};

const CustomDateInput = (props: propType) => {
  const { title, error, touched, date, onChange } = props;
  const errorText = error && touched && error;

  // Singapore timezone
  const singaporeTimeZone = 'Asia/Singapore';

  // Convert date to Singapore time for display
  const zonedDate = date && toZonedTime(date, singaporeTimeZone)?.toISOString();

  const startDate = toZonedTime(
    new Date(Date.now() + 24 * 60 * 60 * 1000),
    singaporeTimeZone
  );

  const handleChange = (selectedDate: string) => {
    // Convert selected date back to UTC
    if (!selectedDate) {
      return;
    }
    const utcDate = fromZonedTime(new Date(selectedDate), singaporeTimeZone);
    onChange(utcDate?.toISOString()); // Pass the UTC date to the parent component
  };

  return (
    <div className="grid grid-cols-1 my-2">
      <div className="font-leagueSpartan-500 text-neutrals-900 text-[16px] mb-2">
        {title}
      </div>
      <DatePicker
        placeholderText="Please select preferred date and time..."
        selected={zonedDate ? new Date(zonedDate) : null}
        onChange={(dateObj) => handleChange(dateObj?.toISOString() || '')}
        showTimeSelect
        dateFormat="PPPp"
        minDate={startDate}
        locale="es" // Use the registered locale if needed
        className={`rounded-md placeholder:text-neutrals-600 w-full ${
          errorText ? 'border-red-500' : 'border-neutrals-400'
        }`}
      />
      <div className="text-red-500">{errorText}</div>
    </div>
  );
};

export default CustomDateInput;
