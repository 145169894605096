import { Field, Formik } from 'formik';
import { useCallback, useEffect, useState } from 'react';

import { useDispatch } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import * as yup from 'yup';
import Api from '../../constants/api';
import { useAppSelector } from '../../redux/hooks';
import { setCampaignRecipientData } from '../../redux/selectGiftSlice';
import { Checkbox } from '../Checkbox/Checkbox';
import Divider from '../common/Divider';
import CheckoutFormInput from './CheckoutFormInput';
import CustomDateInput from './CustomDateInput';

type propType = {
  createOrder: (order: CreateOrderRecipientDetails) => void;
  isDelivery?: boolean;
  pickupAddress?: string;
  isSelfPickupOnly?: boolean;
  isVoucher: boolean;
};

const CheckoutForm = ({
  createOrder,
  isDelivery,
  pickupAddress,
  isSelfPickupOnly,
  isVoucher,
}: propType) => {
  const cartState = useAppSelector((state) => state.selectGiftState);
  const orderAgreements =
    cartState?.campaignRecipientData?.orderAgreements || [];

  const validationSchema = yup.object().shape({
    firstName: yup.string().required('Required'),
    lastName: yup.string().required('Required'),
    phoneNumber: yup.string().required('Required'),
    email: yup
      .string()
      .trim()
      .matches(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i, 'Email is incorrect')
      .required('Required'),
    address: yup
      .string()
      .when((_, schema) =>
        isDelivery === true && !isSelfPickupOnly && !isVoucher
          ? schema.required('Required')
          : schema
      ),
    postalCode: yup
      .string()
      .when((_, schema) =>
        isDelivery === true && !isSelfPickupOnly && !isVoucher
          ? schema.required('Required')
          : schema
      ),
    orderAgreements: yup.array().of(
      yup
        .object()
        .shape({
          isRequired: yup.boolean(),
          checked: yup.boolean(),
        })
        .test(
          '`checked` must be true if `isRequired`',
          'Required',
          ({ isRequired, checked }) => {
            return !isRequired || checked;
          }
        )
    ),
  });
  const dispatch = useDispatch();
  const [giftClaimed, setGiftClaimed] = useState(false);
  const recipientId =
    useSearchParams()[0].get('recid') ||
    cartState.campaignRecipientData?.result.url_id;

  const updateRecipientDetails = useCallback(async () => {
    const campaignRecipientData = await Api.getCampaignRecipient(recipientId!);
    dispatch(setCampaignRecipientData(campaignRecipientData.data));
  }, [dispatch, recipientId]);

  useEffect(() => {
    updateRecipientDetails();
  }, [giftClaimed, updateRecipientDetails]);

  return (
    <div className="font-leagueSpartan">
      <Formik
        initialValues={{
          ...cartState.address,
          orderAgreements: orderAgreements.map((orderAgreement) => ({
            id: orderAgreement.id,
            checked: true, // Default order agreements to checked
            isRequired: orderAgreement.isRequired,
          })),
        }}
        validationSchema={validationSchema}
        onSubmit={({ orderAgreements, ...values }) => {
          console.log(
            {
              agreementIds: orderAgreements
                .filter((orderAgreement) => orderAgreement?.checked)
                .map((orderAgreement) => orderAgreement.id),
              city: 'Singapore',
              ...values,
            },
            'check form val'
          );

          createOrder({
            agreementIds: orderAgreements
              .filter((orderAgreement) => orderAgreement?.checked)
              .map((orderAgreement) => orderAgreement.id),
            city: 'Singapore',
            ...values,
          });
          setGiftClaimed(true);
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          setFieldValue,
        }) => {
          return (
            <form onSubmit={handleSubmit}>
              <div className="grid grid-cols-1">
                <div className="">
                  <CheckoutFormInput
                    type="text"
                    name="firstName"
                    title="First Name"
                    onChange={(e) => {
                      // @ts-ignore
                      let value = e.target.value as string;

                      const test = /^[a-zA-Z\s]{0,30}$/.test(value);
                      if (!test) return;
                      setFieldValue('firstName', value);
                    }}
                    onBlur={handleBlur}
                    value={values.firstName}
                    error={errors.firstName}
                    touched={touched.firstName}
                  />
                </div>
                <div className="">
                  <CheckoutFormInput
                    type="text"
                    name="lastName"
                    title="Last Name"
                    onChange={(e) => {
                      // @ts-ignore
                      let value = e.target.value as string;

                      const test = /^[a-zA-Z\s]{0,30}$/.test(value);
                      if (!test) return;
                      setFieldValue('lastName', value);
                    }}
                    onBlur={handleBlur}
                    value={values.lastName}
                    error={errors.lastName}
                    touched={touched.lastName}
                  />
                </div>

                <div className="">
                  <CheckoutFormInput
                    type="text"
                    name="phoneNumber"
                    title="Phone Number"
                    onChange={(e) => {
                      // @ts-ignore
                      let value = e.target.value as string;

                      const test = /^[0-9]{0,10}$/.test(value);
                      if (!test) return;
                      setFieldValue('phoneNumber', value);
                    }}
                    onBlur={handleBlur}
                    value={values.phoneNumber}
                    error={errors.phoneNumber}
                    touched={touched.phoneNumber}
                  />
                </div>
                <div className="">
                  <CheckoutFormInput
                    type="email"
                    name="email"
                    title="Email Address"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.email}
                    error={errors.email}
                    touched={touched.email}
                  />
                </div>
                {isDelivery && !isSelfPickupOnly && (
                  <>
                    <div className="mt-6">
                      <Divider />
                    </div>
                    <div className="mt-6">
                      <div className="font-leagueSpartan-400 text-[20px] mb-4">
                        Where should we ship your order?
                      </div>
                    </div>
                    <div className="">
                      <CheckoutFormInput
                        type="text"
                        name="address"
                        title="Address"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.address}
                        error={errors.address}
                        touched={touched.address}
                      />
                    </div>
                    <div className="">
                      <CheckoutFormInput
                        type="text"
                        name="apartment"
                        title="Apartment / Floor (Optional)"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.apartment}
                        error={errors.apartment}
                        touched={touched.apartment}
                      />
                    </div>
                    <div className="">
                      <CheckoutFormInput
                        type="text"
                        name="postalCode"
                        title="Postal Code"
                        onChange={(e) => {
                          // @ts-ignore
                          let value = e.target.value as string;

                          const test = /^[0-9]{0,10}$/.test(value);
                          if (!test) return;
                          // if (!(value.length % 4)) value = value + " ";
                          setFieldValue('postalCode', value);
                        }}
                        onBlur={handleBlur}
                        value={values.postalCode}
                        error={errors.postalCode}
                        touched={touched.postalCode}
                      />
                    </div>
                    <div className="mt-6">
                      <Divider />
                    </div>
                    <div className="mt-6">
                      <div className="font-leagueSpartan-400 text-[20px] mb-4">
                        What is your preferred time to ship?
                      </div>
                    </div>
                    <CustomDateInput
                      title="Preferred Date and Time For Delivery"
                      date={values.deliveryDate || null}
                      onChange={(date: string) => {
                        setFieldValue('deliveryDate', date);
                      }}
                    />
                    <p>
                      **We will try our best to have our delivery partners meet
                      the timing**
                    </p>
                  </>
                )}
                {(!isDelivery || isSelfPickupOnly) && !isVoucher && (
                  <>
                    <div className="mt-2">
                      <Divider />
                    </div>
                    <div className="mb-2">
                      <p className="mt-4 font-leagueSpartan-400 text-[18px] text-neutrals-900">
                        Collection Details:
                      </p>
                      <p className="font-leagueSpartan-300 text-[16px] text-neutrals-700">
                        {pickupAddress}
                      </p>
                    </div>
                  </>
                )}
              </div>
              {orderAgreements.map((orderAgreement, index) => {
                const newCheckedValue = !values.orderAgreements[index]?.checked;
                const error = errors.orderAgreements?.at(index) as string;

                return (
                  <Field name={`agreement[${index}]`} key={orderAgreement.id}>
                    {() => (
                      <div className="py-3">
                        <Checkbox
                          checked={values.orderAgreements[index]?.checked}
                          onClick={() => {
                            setFieldValue(`orderAgreements[${index}]`, {
                              id: orderAgreement.id,
                              checked: newCheckedValue,
                              isRequired: orderAgreement.isRequired,
                            });
                          }}
                          text={
                            orderAgreement.text +
                            (orderAgreement.isRequired ? ' (Required)' : '')
                          }
                          readOnly
                        />
                        <div className="text-red-500">{error}</div>
                      </div>
                    )}
                  </Field>
                );
              })}
              <button
                type="submit"
                title="isSubmitting"
                className="mt-3 p-2 px-4 rounded-lg  bg-orange font-leagueSpartan-400 text-[16px] text-white"
              >
                Confirm Selection
              </button>
            </form>
          );
        }}
      </Formik>
    </div>
  );
};

export default CheckoutForm;
